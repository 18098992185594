import { createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";
import { updateTask } from "../tasksSlice";
import { convertTasksToEvents } from "./calendarUtils";

export const fetchTaskEventsFromTasks = createAsyncThunk(
  "calendar/fetchTaskEventsFromTasks",
  async (args, { getState, rejectWithValue, fulfillWithValue }) => {
    const tasks = getState().tasks.data;
    const taskOrders = getState().tasks.order;

    const date = getState().tasks.calendarDate;

    const dates = getState().app.dates;

    const labels = getState().labels.data;

    // Filter taskOrders to only include the ones that are between the date and the date + 1 week
    const taskOrdersFromOrder = Object.values(taskOrders).filter(
      (taskOrder) => {
        if (taskOrder.date) {
          // Check if task date is within 1 week of the date (plus or minus 1 week)
          // const oneWeekBefore = moment(date, "YYYY-MM-DD").subtract(1, "week");
          // const oneWeekAfter = moment(date, "YYYY-MM-DD").add(1, "week");

          //should we do this conditionally???
          var firstDayOfMonth = moment(dates[0], "YYYY-MM-DD").startOf("month");
          var lastDayOfMonth = moment(
            dates[dates.length - 1],
            "YYYY-MM-DD"
          ).endOf("month");

          return moment(
            taskOrder.date.toDate ? taskOrder.date.toDate() : taskOrder.date
          ).isBetween(firstDayOfMonth, lastDayOfMonth, "day", "[]");
        } else {
          return false;
        }
      }
    );

    // Get the tasks from the taskOrders
    var tasksToConvert = [];

    taskOrdersFromOrder.forEach((taskOrder) => {
      taskOrder.order.forEach((taskId) => {
        if (tasks[taskId]) {
          tasksToConvert.push(tasks[taskId]);
        }
      });
    });

    const newEvents = convertTasksToEvents(tasksToConvert, labels);

    // Take the array and group by id
    const newTaskEvents = newEvents.reduce((acc, cur) => {
      acc[cur.id] = cur;
      return acc;
    }, {});

    // Go through stagedTaskEvents and replace the ones that are in newTaskEvents
    const stagedTaskEvents = getState().calendar.stagedTaskEvents;

    Object.keys(stagedTaskEvents).forEach((key) => {
      if (newTaskEvents[key]) {
        newTaskEvents[key] = stagedTaskEvents[key];
      }
    });

    return fulfillWithValue(newTaskEvents);
  }
);

export const updateTaskFromEvent = createAsyncThunk(
  "calendar/updateTaskFromEvent",
  async (event, { dispatch, getState, rejectWithValue, fulfillWithValue }) => {
    const tasks = getState().tasks.data;

    const start = event.start;
    const end = event.end;
    const taskId = event.id;

    // Get the duration in seconds from the start and end time
    let duration = moment(end).diff(moment(start), "seconds");

    // if duration is not a number, set it to 1 hour in seconds
    if (isNaN(duration)) {
      duration = 3600;
    }

    // Update the task
    const task = tasks[taskId];

    dispatch(
      updateTask({
        taskId: taskId,
        newData: {
          date: start,
          start: start,
          estimated_time: duration,
          listId: null,
        },
        currentTask: task,
        saveGhostOrder: false,
        updateOrder: true,
      })
    );

    return fulfillWithValue(event);
  }
);
