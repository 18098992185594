import { ArrowLeftCircleIcon } from "@heroicons/react/24/outline";
import { FaArrowRightLong } from "react-icons/fa6";
import ellieLogo from "../../../images/logo.svg";
import { FiMoreVertical, FiPlus } from "react-icons/fi";
import { Dropdown, Menu, Switch } from "antd";
import { DownOutlined, MoreOutlined } from "@ant-design/icons";
import {
  IoAdd,
  IoChevronDown,
  IoClose,
  IoSync,
  IoWarning,
} from "react-icons/io5";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MdOutlineAdd } from "react-icons/md";
import axios from "axios";
import { analytics, notionServerUrl } from "../../../utils";
import { db } from "../../../firebase";
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { v4 } from "uuid";
import { updateCurrentUser } from "../../../redux/appSlice";
import { toast } from "sonner";
import _, { last } from "lodash";
import DynamicFilter from "./DynamicFilter";
import DynamicQueryFilter from "./DynamicQueryFilter";
import CustomDropdown from "./CustomDropdown";

export default function NotionDatabaseItem({
  connection,
  databaseName,
  ellieList,
  activateEditMode,
  notionDatabases,
  setNotionDatabases,
  setRefresh,
  loadingNotionFetch,
  workspace,
  onClose,
  notionUser,
  isCreation = false,
}) {
  const [editModeActive, setEditModeActive] = useState(activateEditMode);

  const [importDoneItems, setImportDoneItems] = useState(
    connection?.importDoneItems || false
  );
  const [syncChanges, setSyncChanges] = useState(
    connection?.syncChanges || false
  );

  const [selectedNotionDatabase, setSelectedNotionDatabase] = useState({});
  const [selectedEllieList, setSelectedEllieList] = useState({});

  const [filter, setFilter] = useState(connection?.filter || {});
  const [conditions, setConditions] = useState(
    connection?.conditions || { complete: [null, null] }
  );

  const lists = useSelector((state) => state.tasks.lists || {});
  const userId = useSelector((state) => state.app.uid);

  useEffect(() => {
    if (connection) {
      setSelectedNotionDatabase(connection.notion_database);
      setSelectedEllieList({
        key: connection.list_id,
        label: `${lists[connection.list_id].icon} ${
          lists[connection.list_id].title
        }`,
        valueLabel: lists[connection.list_id].title,
      });

      setFilter(connection.filter);
      setConditions(connection.conditions);
      setSyncChanges(connection.syncChanges);
    }
  }, [connection, lists, editModeActive]);

  // If notionDatabases changes, update the selected database
  useEffect(() => {
    if (
      notionDatabases &&
      notionDatabases.length > 0 &&
      selectedNotionDatabase
    ) {
      // Find the notion database that matches the current
      // selected database key
      const selected = notionDatabases.find(
        (db) => db.key === selectedNotionDatabase.key
      );

      if (selected) {
        setSelectedNotionDatabase(selected);
      }
    }
  }, [notionDatabases, selectedNotionDatabase]);

  const handleConnectionDelete = async () => {
    try {
      if (!connection?.id) return;
      // Delete connection
      const connectionRef = doc(
        db,
        "users",
        userId,
        "notion_connections",
        connection.id
      );

      await deleteDoc(connectionRef);

      analytics("Notion connection deleted");

      setEditModeActive(false);
      onClose && onClose();
      setRefresh((prev) => prev + 1);
    } catch (error) {
      console.error("Error deleting connection", error);
    }
  };

  const deleteImportedItems = async () => {
    try {
      // Query for tasks with matching connection_id
      const tasksRef = collection(db, "users", userId, "tasks");
      const q = query(
        tasksRef,
        where("notionConnectionId", "==", connection.id)
      );
      const querySnapshot = await getDocs(q);

      // Delete matching tasks
      const deletePromises = querySnapshot.docs.map((doc) =>
        deleteDoc(doc.ref)
      );
      await Promise.all(deletePromises);

      analytics("Notion bulk delete imported tasks called");

      console.log(
        `Deleted ${querySnapshot.size} tasks associated with connection ${connection.id}`
      );
    } catch (error) {
      console.error("Error deleting imported tasks", error);
    }
  };

  const resyncNotionTasks = async () => {
    try {
      toast.message("Syncing Notion tasks");

      const response = await axios.post(
        `${notionServerUrl}/syncNotion?userId=${userId}&forceRefresh=true`
      );
      const data = response.data;
    } catch (error) {
      console.error("Error fetching notion tasks:", error);
      toast.error("Error syncing Notion tasks");
    }
  };

  const handleMenuClick = (e) => {
    if (e.key === "edit") {
      setEditModeActive(true);
    }

    if (e.key === "resync") {
      analytics("Notion force re-sync called");
      resyncNotionTasks();
    }

    if (e.key === "delete") {
      handleConnectionDelete();
    }

    if (e.key === "deleteAll") {
      deleteImportedItems();
    }
  };

  const items = [
    {
      key: "edit",
      label: "Edit connection settings",
    },
    {
      key: "resync",
      label: (
        <div className="flex flex-row gap-2 items-center">
          <IoSync /> Re-sync imported tasks
        </div>
      ),
    },
    {
      type: "divider",
    },
    {
      key: "delete",
      label: "Delete connection",
      danger: true,
    },
    {
      key: "deleteAll",
      label: "Delete all imported tasks",
      danger: true,
    },
  ];

  const [ellieListOptions, setEllieListOptions] = useState([]);

  useEffect(() => {
    // Process ellie lists
    const ellieLists = Object.keys(lists).map((listId) => {
      return {
        label: `${lists[listId].icon} ${lists[listId].title}`,
        key: listId,
        valueLabel: lists[listId].title,
      };
    });

    // let's add the brain_dump list to the list of options at the top
    ellieLists.unshift({
      label: "🧠 Brain dump",
      key: "brain_dump",
      valueLabel: "Brain dump",
    });

    setEllieListOptions(ellieLists);
  }, [lists]);

  const [availableFilterProperties, setAvailableFilterProperties] = useState(
    []
  );

  const [availableMapProperties, setAvailableMapProperties] = useState([]);

  useEffect(() => {
    if (
      selectedNotionDatabase &&
      Object.values(selectedNotionDatabase)?.length > 0
    ) {
      const properties = selectedNotionDatabase.properties;
      // We only want to support certain types, so only include those
      // select, checkbox, status

      const supportedProperties = Object.entries(properties)
        .map(([key, property]) => {
          if (
            property.type === "select" ||
            property.type === "checkbox" ||
            property.type === "status" ||
            property.type === "multi_select" ||
            property.type === "people"
          ) {
            return {
              label: property.name,
              key: property.id,
              ...property,
            };
          }
        })
        .filter((property) => property !== undefined);

      const supportedMapProperties = Object.entries(properties)
        .map(([key, property]) => {
          if (
            property.type === "select" ||
            property.type === "checkbox" ||
            property.type === "status"
          ) {
            return {
              label: property.name,
              key: property.id,
              ...property,
            };
          }
        })
        .filter((property) => property !== undefined);

      setAvailableMapProperties(supportedMapProperties);

      setAvailableFilterProperties(supportedProperties);
    }
  }, [selectedNotionDatabase]);

  const saveConnection = async () => {
    try {
      if (
        !selectedNotionDatabase ||
        Object.keys(selectedNotionDatabase).length === 0
      ) {
        // toast.error("Please select a Notion database");
        throw new Error("Please select a Notion database");
      }

      if (!selectedEllieList || Object.keys(selectedEllieList).length === 0) {
        // toast.error("Please select an Ellie list");
        throw new Error("Please select an Ellie list");
      }

      if (!conditions?.complete?.[0]) {
        // toast.error("Please map the complete property");
        throw new Error("Please map the complete property");
      }

      if (!conditions?.complete?.[1]) {
        // toast.error("Please map the incomplete property");
        throw new Error("Please map the incomplete property");
      }

      let notion_connection_id = connection?.id || v4();

      var connectionData = {
        notion_database: selectedNotionDatabase,
        list_id: selectedEllieList.key,
        filter: filter?.and && filter?.and?.length > 0 ? filter : null,
        conditions: conditions || null,
        syncChanges,
        id: notion_connection_id,
        last_error: null,
        workspace_id: workspace.workspace_id || null,
      };

      // Create a reference to the specific document in the user's notion_connections collection
      const connectionRef = doc(
        db,
        "users",
        userId,
        "notion_connections",
        notion_connection_id
      );

      console.log("Saving connection data:", connectionData);

      // Check notion_database.properties and make sure that the keys are valid
      // A key cannot be empty, if it is (which it could be for title)
      // Swap the key with the id
      connectionData.notion_database.properties = Object.entries(
        connectionData.notion_database.properties
      ).reduce((acc, [key, value]) => {
        if (key === "") {
          acc[value.id] = value;
        } else {
          acc[key] = value;
        }
        return acc;
      }, {});

      console.log("Saving connection data2:", connectionData);

      // Set the document data
      await setDoc(connectionRef, connectionData);

      if (activateEditMode) {
        // We are creating a new connection
        analytics("Notion connection created");
        resyncNotionTasks();
      } else {
        analytics("Notion connection updated");
        resyncNotionTasks();
      }

      setEditModeActive(false);
      onClose && onClose();
      setRefresh((prev) => prev + 1);
    } catch (error) {
      console.error("Error saving connection:", error);
      toast.error("Error saving connection: " + error.message);
    }
  };

  function isValidFilter(filter) {
    // We should check the filter against the available properties
    // If the property is not available, return { valid: false, message: "Property no longer available" }
    // If the property type is no longer the same, return { valid: false, message: "Property type changed" }

    // If the property is available, return { valid: true }

    if (!filter?.when) {
      return {
        valid: true,
      };
    }

    const prop = availableMapProperties.find(
      (p) => p.id === filter?.when?.notion_property
    );

    if (!prop) {
      return {
        valid: false,
        message: "Property no longer available, please change",
      };
    }

    return { valid: true };
  }

  if (editModeActive) {
    return (
      <div className="p-5 flex flex-col gap-4 border border-neutral-200 dark:border-neutral-700 rounded-lg w-full">
        <h2 className="text-lg font-semibold">
          {activateEditMode ? "New" : "Edit"} Connection Settings
        </h2>

        {connection?.last_error && (
          <div className="text-orange-700  dark:text-orange-300 flex flex-col gap-2 items-start bg-orange-100 dark:bg-orange-800/30 rounded-2xl px-4 py-2">
            <div className="flex flex-row gap-2 items-center">
              <IoWarning className=" h-4 w-4" />
              <div className="text-sm  font-semibold">
                Something went wrong, check settings below (or contact support)
              </div>
            </div>
          </div>
        )}

        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-6">
            <div className="flex flex-col gap-1">
              <h3 className="text-sm font-medium mb-2">
                Import items from this Notion database
              </h3>
              <div className="flex flex-row gap-4 items-center">
                <CustomDropdown
                  options={notionDatabases}
                  onSelect={(selected) => {
                    setSelectedNotionDatabase(selected);
                  }}
                  value={selectedNotionDatabase?.key || null}
                  defaultValue="Select database"
                  className="w-8/12"
                  disabled={
                    selectedNotionDatabase &&
                    Object.keys(selectedNotionDatabase).length === 0
                  }
                  leadingIcon={
                    <img
                      className="h-5 w-5 rounded-md"
                      src={require("../../../images/Notion_app_logo.png")}
                      alt="Notion Logo"
                    />
                  }
                  isSelectDatabase={true}
                />
                <div
                  className={`flex flex-row text-neutral-500 gap-1.5 cursor-pointer hover:text-neutral-700 items-center`}
                  onClick={() => setRefresh((prev) => prev + 1)}
                  disabled={loadingNotionFetch}
                >
                  <IoSync
                    className={` ${loadingNotionFetch && "animate-spin"}`}
                  />
                  <div>Refresh</div>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-1">
              <h3 className="text-sm font-medium mb-2">
                As tasks into this Ellie list
              </h3>
              <CustomDropdown
                options={ellieListOptions}
                onSelect={(selected) => setSelectedEllieList(selected)}
                defaultValue="Select list"
                className="w-8/12"
                value={selectedEllieList?.key || null}
                leadingIcon={
                  <div className="text-lg">
                    {selectedEllieList &&
                    Object.values(selectedEllieList).length > 0
                      ? selectedEllieList?.key === "brain_dump"
                        ? "🧠"
                        : lists[selectedEllieList?.key]?.icon
                      : "👋"}
                  </div>
                }
                disabled={false}
              />
            </div>
          </div>

          {/* Divider */}
          <div className="border-b border-neutral-200 dark:border-neutral-700 my-2"></div>

          <div className="flex flex-col gap-6">
            <div className="flex flex-col gap-1">
              <h3 className="text-sm font-medium">Filter imported items</h3>
              <p className="text-xs text-gray-500">
                We'll sync only tasks matching the defined filters
              </p>
            </div>
            <div className="flex flex-col gap-6">
              <div className="flex flex-col gap-1">
                <DynamicQueryFilter
                  availableProperties={availableFilterProperties}
                  setFilters={setFilter}
                  disabled={
                    selectedNotionDatabase &&
                    Object.keys(selectedNotionDatabase).length === 0
                  }
                  filters={filter}
                  notionUser={notionUser}
                />
              </div>
            </div>
          </div>

          <div className="border-b border-neutral-200 dark:border-neutral-700 my-2"></div>

          <div className="flex flex-col gap-6">
            <div className="flex flex-col gap-1">
              <h3 className="text-sm font-medium">
                Map Notion database properties to Ellie fields
              </h3>
              <p className="text-xs text-gray-500">
                This will help us sync the fields correctly between Notion and
                Ellie
              </p>
            </div>

            <div className="flex flex-col gap-6">
              <div className="flex flex-col gap-1">
                <h3 className="text-xs font-medium mb-2">
                  Which Notion property represents task completed?
                </h3>
                <div
                  className={`w-full ${
                    !isValidFilter(conditions?.complete?.[0]).valid
                      ? "border border-solid border-red-100 dark:border-red-800 rounded-lg py-2.5 pb-4 px-2"
                      : "px-2"
                  }`}
                >
                  {!isValidFilter(conditions?.complete?.[0]).valid && (
                    <div className="text-red-500 text-sm mb-3">
                      {isValidFilter(conditions?.complete?.[0]).message}
                    </div>
                  )}
                  <DynamicFilter
                    availableProperties={availableMapProperties}
                    onChange={(filterData) => {
                      setConditions({
                        ...conditions,
                        complete: [
                          {
                            value: true,
                            when: {
                              property_id: filterData?.property,
                              notion_property: filterData?.property,
                              notion_value: filterData?._valueId,
                            },
                          },
                          conditions?.complete?.[1] || null,
                        ],
                      });
                    }}
                    disabled={
                      selectedNotionDatabase &&
                      Object.keys(selectedNotionDatabase).length === 0
                    }
                    filter={conditions?.complete?.[0]}
                    notionUserId={notionUser?.id}
                  />
                </div>
              </div>
              <div className="flex flex-col gap-1">
                <h3 className="text-xs font-medium mb-2">
                  Which Notion property represents task incomplete?
                </h3>
                <div
                  className={`w-full ${
                    !isValidFilter(conditions?.complete?.[1]).valid
                      ? "border border-solid border-red-100 dark:border-red-800 rounded-lg py-2.5 pb-4 px-2"
                      : "px-2"
                  }`}
                >
                  {!isValidFilter(conditions?.complete?.[1]).valid && (
                    <div className="text-red-500 text-sm mb-3">
                      {isValidFilter(conditions?.complete?.[1]).message}
                    </div>
                  )}
                  <DynamicFilter
                    availableProperties={availableMapProperties}
                    onChange={(filterData) => {
                      setConditions({
                        ...conditions,
                        complete: [
                          conditions?.complete?.[0] || null,
                          {
                            value: false,
                            when: {
                              property_id: filterData?.property,
                              notion_property: filterData?.property,
                              notion_value: filterData?._valueId,
                            },
                          },
                        ],
                      });
                    }}
                    notionUserId={notionUser?.id}
                    disabled={
                      selectedNotionDatabase &&
                      Object.keys(selectedNotionDatabase).length === 0
                    }
                    filter={conditions?.complete?.[1]}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="border-b border-neutral-200 dark:border-neutral-700 my-2"></div>

          <div className="flex justify-between items-center px-2">
            <div>
              <h3 className="text-sm font-medium">
                Sync changes in Ellie to Notion
              </h3>
              <p className="text-xs text-gray-500">
                Deleting a task in Ellie{" "}
                <b>
                  <u>will not</u>
                </b>{" "}
                delete anything in Notion.
              </p>
            </div>
            <Switch
              checked={syncChanges}
              onChange={(checked) => {
                setSyncChanges(checked);
              }}
            />
          </div>

          <div className="flex justify-end gap-4 mt-4">
            <button
              className="px-4 py-2 bg-gray-200 hover:bg-gray-300 dark:bg-neutral-700 hover:dark:bg-neutral-600 rounded-lg"
              onClick={() => {
                setEditModeActive(false);
                onClose && onClose();
              }}
            >
              Cancel
            </button>
            <button
              className="px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white rounded-lg"
              onClick={saveConnection}
            >
              {activateEditMode ? "Create Connection" : "Save changes"}
            </button>
          </div>
        </div>
      </div>
    );
  } else if (databaseName && ellieList) {
    return (
      <div className="p-5 flex flex-row gap-2 justify-between items-center border border-neutral-200 dark:border-neutral-700 rounded-lg w-full">
        <div className="flex flex-row gap-4 items-center">
          <div className="flex items-center gap-2">
            <img
              className="h-6 w-6 rounded-md"
              src={require("../../../images/Notion_app_logo.png")}
              alt="Notion logo"
            />
            <div className="text-sm">{databaseName}</div>
          </div>
          <FaArrowRightLong className="text-neutral-400" />
          <div className="flex items-center gap-2">
            <div className="text-xl">{ellieList?.icon}</div>
            <div className="text-sm">{ellieList?.title}</div>
          </div>
        </div>

        <div className="flex flex-row gap-2 items-center justify-end">
          {connection?.last_error && (
            <div
              onClick={() => {
                setEditModeActive(true);
              }}
              className="text-orange-700  dark:text-orange-300 flex flex-row gap-1 items-center bg-orange-100 dark:bg-orange-800/30 rounded-2xl px-4 py-2"
            >
              <IoWarning />
              <div className="text-xs font-semibold">Sync Error</div>
            </div>
          )}

          <Dropdown
            menu={{ items, onClick: handleMenuClick }}
            trigger={["click"]}
          >
            <a onClick={(e) => e.preventDefault()}>
              <FiMoreVertical className="text-neutral-400 text-2xl cursor-pointer" />
            </a>
          </Dropdown>
        </div>
      </div>
    );
  } else {
    return null;
  }
}

export const convertNotionFilterToAvailableProperties = (
  notionFilter,
  supportedProperties
) => {
  if (!notionFilter || !notionFilter.and) {
    return { validFilters: [], invalidFilters: [] };
  }

  const validFilters = [];
  const invalidFilters = [];

  notionFilter.and.forEach((filterItem) => {
    const propertyId = filterItem.id;
    const [conditionType, conditionValue] = Object.entries(filterItem).find(
      ([key, value]) => key !== "property" && typeof value === "object"
    );

    const property = supportedProperties.find((p) => p.id === propertyId);
    if (!property) {
      invalidFilters.push(filterItem);
      return;
    }

    let value;
    switch (property.type) {
      case "checkbox":
      case "select":
      case "status":
        value = conditionValue.equals;
        break;
      case "multi_select":
        value = conditionValue.contains;
        break;
      case "people":
        value = conditionValue.contains || conditionValue.does_not_contain;
        break;
      default:
        value = null;
    }

    if (value !== null) {
      validFilters.push({
        selectedPropertyKey: property.id,
        value: value,
      });
    } else {
      invalidFilters.push(filterItem);
    }
  });

  return { validFilters, invalidFilters };
};
